import {getDayMonthText, getEventsForDay, getSelectedDate} from '../../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventListOwnProps, MonthlyCalendarEventListRuntimeProps} from './interfaces'
import {MonthlyCalendarEventList as MonthlyCalendarEventListPresentation} from './monthly-calendar-event-list'

const mapRuntime = (context: AppProps): MonthlyCalendarEventListRuntimeProps => {
  const selectedDate = getSelectedDate(context.state)

  return {
    state: context.state,
    events: getEventsForDay(context.state, selectedDate),
    dateText: getDayMonthText(context.state, selectedDate),
    selectedDate,
    openMonthlyCalendarEvent: (eventId: string, selectedTimestamp: string) =>
      context.actions.openMonthlyCalendarEvent(eventId, selectedTimestamp),
    closeMonthlyCalendarPopup: () => context.actions.closeMonthlyCalendarPopup(),
  }
}

export const MonthlyCalendarEventList = connect<MonthlyCalendarEventListOwnProps, MonthlyCalendarEventListRuntimeProps>(
  mapRuntime,
)(MonthlyCalendarEventListPresentation)

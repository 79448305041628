import {getDayNumber, getEventsForDay, getToday} from '../../../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MobileMonthlyDayOwnProps, MobileMonthlyDayRuntimeProps} from './interfaces'
import {MobileMonthlyDay as MobileMonthlyDayPresentation} from './mobile-monthly-day'

const mapRuntime = (context: AppProps, {day}: MobileMonthlyDayOwnProps): MobileMonthlyDayRuntimeProps => ({
  events: getEventsForDay(context.state, day),
  past: day < getToday(context.state),
  today: day === getToday(context.state),
  dayNumber: getDayNumber(context.state, day),
  currentDate: day,
  borderStyleRight: getBorderStyle(context, 'right'),
  borderStyleBottom: getBorderStyle(context, 'bottom'),
})

export const MobileMonthlyDay = connect<MobileMonthlyDayOwnProps, MobileMonthlyDayRuntimeProps>(mapRuntime)(
  MobileMonthlyDayPresentation,
)

import {ChevronRight} from '@wix/wix-ui-icons-common'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../../../../commons/hooks/dates'
import {isMultiDay} from '../../../../../../../../../../commons/selectors/event'
import {getMultiDayText} from '../../../../../../../selectors/events'
import s from '../monthly-calendar-event-list.scss'
import {MonthlyCalendarEventListItemProps} from './interfaces'

export const MonthlyCalendarEventListItem = (props: MonthlyCalendarEventListItemProps) => {
  const {startDate} = useEventDateInformation(props.event.id)
  const {t, state, selectedDate, event, onClick, onKeyPress} = props

  const multiDay = isMultiDay(state, event)
  const multiDayText = getMultiDayText(state, event.id, selectedDate, t)

  return (
    <li
      className={s.event}
      onClick={onClick}
      onKeyPress={onKeyPress}
      tabIndex={0}
      data-hook={`event-list-item-${event.id}`}
    >
      <div className={s.info}>
        <div className={s.time} data-hook={`event-time-${event.id}`}>
          {multiDay ? multiDayText : startDate}
        </div>
        <div className={s.title} data-hook={`event-title-${event.id}`}>
          {event.title}
        </div>
      </div>
      <div className={s.chevron}>
        <ChevronRight size="1.6em" />
      </div>
    </li>
  )
}

import classNames from 'classnames'
import React from 'react'
import {TriangleDirection, TriangleProps} from './interfaces'
import s from './triangle.scss'

export class Triangle extends React.PureComponent<TriangleProps> {
  render() {
    const {direction} = this.props
    return (
      <div
        className={classNames(s.triangle, {
          [s.left]: direction === TriangleDirection.left,
          [s.right]: direction === TriangleDirection.right,
        })}
      >
        <div className={s.outer} />
        <div className={s.inner} />
      </div>
    )
  }
}

import {withTranslation} from '@wix/yoshi-flow-editor'
import {
  getDaysInGridMonth,
  getReferencedMonth,
  getWeekdaysFromStartWeekday,
} from '../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../utils/calendar'
import {AppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {MonthlyCalendarGridOwnProps, MonthlyCalendarGridRuntimeProps} from './interfaces'
import {MonthlyCalendarGrid as CalendarGridPresentation} from './monthly-calendar-grid'

const mapRuntime = (context: AppProps): MonthlyCalendarGridRuntimeProps => ({
  shownDays: getDaysInGridMonth(context.state, getReferencedMonth(context.state)),
  weekdayNames: getWeekdaysFromStartWeekday(context.state),
  borderStyle: getBorderStyle(context),
})

export const MonthlyCalendarGrid = connect<MonthlyCalendarGridOwnProps, MonthlyCalendarGridRuntimeProps>(mapRuntime)(
  withTranslation()(CalendarGridPresentation),
)

import {ChevronDown} from '@wix/wix-ui-icons-common'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {isMultiDay} from '../../../../../../../../../../commons/selectors/event'
import {getMultiDayText} from '../../../../../../../selectors/events'
import sc from '../../../../../../classes.scss'
import {MobileMonthlyEventDetails} from './details'
import {MobileMonthlyEventProps} from './interfaces'
import s from './mobile-monthly-event.scss'

export const MobileMonthlyEvent = ({
  state,
  event,
  currentDate,
  eventTime,
  openMonthlyCalendarEvent,
  selectedEventId,
  selectedDate,
}: MobileMonthlyEventProps) => {
  const {t} = useTranslation()

  const multiDay = isMultiDay(state, event)
  const multiDayText = getMultiDayText(state, event.id, currentDate, t)

  const handleEventClick = () => {
    openMonthlyCalendarEvent(event.id, currentDate)
  }

  if (selectedEventId === event.id && currentDate === selectedDate) {
    return <MobileMonthlyEventDetails event={event} />
  }

  return (
    <div
      className={classNames(s.container, sc.textLineHeight)}
      onClick={handleEventClick}
      data-hook={`event-${event.id}`}
    >
      <div className={s.time} data-hook={`event-time-${event.id}`}>
        {multiDay ? multiDayText : eventTime}
      </div>
      <div className={s.title} data-hook={`event-title-${event.id}`}>
        {event.title}
        <div className={s.chevron}>
          <ChevronDown size="1.2em" />
        </div>
      </div>
    </div>
  )
}

import {isMobile} from '../../../../../commons/selectors/environment'
import {isAnyPageLoaded} from '../../selectors/calendar-layout'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Calendar as CalendarPresentation} from './calendar'
import {CalendarOwnProps, CalendarRuntimeProps} from './interfaces'

const mapRuntime = ({state, host}: AppProps): CalendarRuntimeProps => ({
  isMobile: isMobile(state),
  loading: !isAnyPageLoaded(state),
  width: host.dimensions.width,
})

export default connect<CalendarOwnProps, CalendarRuntimeProps>(mapRuntime)(CalendarPresentation)

import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventDateInformation, isMultiDay} from '../../../../../../../../../commons/selectors/event'
import {getMultiDayText} from '../../../../../../selectors/events'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {DayEvents as DayEventsPresentation} from './day-events'
import {EventInfoOwnProps, EventInfoRuntimeProps} from './interfaces'

const mapRuntime = (context: AppProps, {t, events, currentDate}: EventInfoOwnProps): EventInfoRuntimeProps => {
  const firstEvent = events[0]

  return {
    firstEventTime: getEventDateInformation(context.state, events[0].id).startTime,
    multiDay: isMultiDay(context.state, firstEvent),
    multiDayText: getMultiDayText(context.state, firstEvent.id, currentDate, t),
  }
}

export const DayEvents = withTranslation()(
  connect<EventInfoOwnProps, EventInfoRuntimeProps>(mapRuntime)(DayEventsPresentation),
)

import {getEventDateInformation} from '../../../../../../../../../../commons/selectors/event'
import {getSelectedDate, getSelectedEventId} from '../../../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../../../app/interfaces'
import {connect} from '../../../../../../runtime-context/connect'
import {MobileMonthlyEventOwnProps, MobileMonthlyEventRuntimeProps} from './interfaces'
import {MobileMonthlyEvent as MobileMonthlyEventPresentation} from './mobile-monthly-event'

const mapRuntime = (
  {state, actions}: AppProps,
  ownProps: MobileMonthlyEventOwnProps,
): MobileMonthlyEventRuntimeProps => {
  const {event} = ownProps

  return {
    state,
    selectedEventId: getSelectedEventId(state),
    selectedDate: getSelectedDate(state),
    eventTime: getEventDateInformation(state, event.id).startTime,
    openMonthlyCalendarEvent: actions.openMonthlyCalendarEvent,
  }
}

export const MobileMonthlyEvent = connect<MobileMonthlyEventOwnProps, MobileMonthlyEventRuntimeProps>(mapRuntime)(
  MobileMonthlyEventPresentation,
)

import {withTranslation} from '@wix/yoshi-flow-editor'
import {
  getDayNumber,
  getEventsForDay,
  getSelectedDate,
  getToday,
  isDayInCurrentMonth,
} from '../../../../../selectors/calendar-layout'
import {getActionBackground, getBorderStyle, getOtherMonthBackground} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {CalendarCell as CalendarCellPresentation} from './calendar-cell'
import {CalendarCellOwnProps, CalendarCellRuntimeProps} from './interfaces'

const mapRuntime = (context: AppProps, {timestamp}: CalendarCellOwnProps): CalendarCellRuntimeProps => ({
  events: getEventsForDay(context.state, timestamp),
  isPast: timestamp < getToday(context.state),
  isToday: timestamp === getToday(context.state),
  isCurrentMonth: isDayInCurrentMonth(context.state, timestamp),
  dayNumber: getDayNumber(context.state, timestamp),
  selectionExists: Boolean(getSelectedDate(context.state)),
  active: timestamp === getSelectedDate(context.state),
  borderStyle: getBorderStyle(context),
  otherMonthBackground: getOtherMonthBackground(context),
  actionBackground: getActionBackground(context),
  openMonthlyCalendarPopup: context.actions.openMonthlyCalendarPopup,
  openMonthlyCalendarEvent: context.actions.openMonthlyCalendarEvent,
  closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
})

export const CalendarCell = withTranslation()(
  connect<CalendarCellOwnProps, CalendarCellRuntimeProps>(mapRuntime)(CalendarCellPresentation),
)

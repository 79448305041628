import {withTranslation} from '@wix/yoshi-flow-editor'
import {isEditor} from '../../../../../../commons/selectors/environment'
import {getReferencedMonthName} from '../../../selectors/calendar-layout'
import {getCalendarWeekStartDay} from '../../../selectors/list-settings'
import {getComponentConfig} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps} from './interfaces'
import {MonthlyCalendar as MonthlyCalendarPresentation} from './monthly-calendar'

const mapRuntime = ({actions, state}: AppProps): MonthlyCalendarRuntimeProps => ({
  fullMonthText: getReferencedMonthName(state),
  addCalendarMonth: actions.addCalendarMonth,
  subCalendarMonth: actions.subCalendarMonth,
  resetCalendar: actions.resetCalendar,
  loading: state.calendarLayout.loading,
  calendarWeekStartDay: getCalendarWeekStartDay(getComponentConfig(state)),
  isEditor: isEditor(state),
})

export const MonthlyCalendar = connect<MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps>(mapRuntime)(
  withTranslation()(MonthlyCalendarPresentation),
)

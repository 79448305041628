import {isEditor} from '../../../../../../../../commons/selectors/environment'
import {
  getEventDetailsStyleHash,
  getGridCellLocation,
  getSelectedDate,
  getSelectedEventId,
} from '../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MonthlyCalendarPopupOwnProps, MonthlyCalendarPopupRuntimeProps} from './interfaces'
import {MonthlyCalendarPopup as MonthlyCalendarPopupPresentation} from './monthly-calendar-popup'

const mapRuntime = (context: AppProps): MonthlyCalendarPopupRuntimeProps => {
  const selectedDate = getSelectedDate(context.state)

  const insideEditor = isEditor(context.state)
  const {weekIndex, weekDayIndex} = getGridCellLocation(context.state, selectedDate)

  return {
    week: weekIndex,
    weekDayIndex,
    selectedDate,
    open: Boolean(selectedDate),
    selectedEventId: getSelectedEventId(context.state),
    popupStyleHash: insideEditor && getEventDetailsStyleHash(context),
    isEditor: insideEditor,
    rtl: context.isRTL,
    closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
  }
}

export const MonthlyCalendarPopup = connect<MonthlyCalendarPopupOwnProps, MonthlyCalendarPopupRuntimeProps>(mapRuntime)(
  MonthlyCalendarPopupPresentation,
)

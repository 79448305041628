import {isMobile} from '../../../../../../commons/selectors/environment'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {TodayButtonOwnProps, TodayButtonRuntimeProps} from './interfaces'
import {TodayButton as TodayButtonPresentation} from './today-button'

const mapRuntime = ({state}: AppProps): TodayButtonRuntimeProps => {
  return {
    isMobile: isMobile(state),
  }
}

export const TodayButton = connect<TodayButtonOwnProps, TodayButtonRuntimeProps>(mapRuntime)(TodayButtonPresentation)
